<!--生产任务查询-->
<template>
    <div class="InvoiceList">
        <div class="currencyTable table-bg" v-loading="loading">
            <div class="table-header">
                <div class="el_left">
                    <slot name="queryCriteria"></slot>
                    <slot name="buttons"></slot>
                </div>
                <div class="el_right">
                    <div class="el_left setUp">
                        <el-button v-popover:popover>
                            <i class="iconfont iconshezhi"></i>
                        </el-button>
                        <el-popover
                            ref="popover"
                            placement="bottom-start"
                            title="自定义列表项"
                            width="540"
                            trigger="click"
                            v-model="isPopover"
                        >
                            <div class="tablePopover">
                                <div class="popoverContent">
                                    <el-checkbox-group v-model="selectListItem">
                                        <template v-for="(item, index) in tableData.headerData">
                                            <el-checkbox :key="index" :label="item.field">
                                                {{ item.title }}
                                            </el-checkbox>
                                        </template>
                                    </el-checkbox-group>
                                </div>
                                <div class="el_right">
                                    <el-button size="mini" @click="isPopover = false">
                                        取消
                                    </el-button>
                                    <el-button size="mini" type="primary" @click="confirmListItem">
                                        确定
                                    </el-button>
                                </div>
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
            <el-table
                border
                stripe
                :data="tableList"
                ref="multipleTable"
                :highlight-current-row="true"
                show-overflow-tooltip
            >
                <template v-for="(item,index) in tableData.headerData">
                    <el-table-column
                        :key="index"
                        :prop="item.field"
                        :label="item.title"
                        :width="item.width"
                    >
                    </el-table-column>
                </template>
            </el-table>
        </div>
    </div>
</template>

<script>
import currencyTable from '@/components/table/index.vue';
export default {
    name: 'invoice-list',
    props: {
        extr: Object,
    },
    data() {
        return {
            isPopover: false,
            // 自定义列表项数据
            listDisplayItems: [],
            // 已选中显示项
            selectListItem: [],
            tableData: {
                headerData: [{
                    field: 'pcb_number',
                    title: '发货单号',
                    width: 200,
                }, {
                    field: 'task_number',
                    title: '任务单号',
                    width: 200,
                }, {
                    field: 'customer_name',
                    title: '客户名称',
                    width: 150,
                }, {
                    field: 'project_name',
                    title: '工程名称',
                    width: 150,
                }, {
                    field: 'mixstation_name',
                    title: '场站',
                }, {
                    field: 'line_name',
                    title: '生产机组',
                }, {
                    field: 'place_name',
                    title: '施工部位',
                    width: 150,
                }, {
                    field: 'strength_grade_name',
                    title: '产品标号',
                }, {
                    field: 'special_require_name',
                    title: '附加型号',
                }, {
                    field: 'pouring_name',
                    title: '浇筑方式',
                }, {
                    field: 'shch',
                    title: '车号',
                }, {
                    field: 'plate_number',
                    title: '车牌号',
                    width: 120,
                }, {
                    field: 'sjxm',
                    title: '司机',
                    width: 100,
                }, {
                    field: 'deliver_time',
                    title: '打印时间',
                    width: 160,
                }, {
                    field: 'virtual_volume',
                    title: '虚方',
                }, {
                    field: 'back_volume',
                    title: '退砼方量',
                }, {
                    field: 'concrete_volume',
                    title: '砼方量',
                }, {
                    field: 'mortar_volume',
                    title: '砂浆方量',
                }, {
                    field: 'volume',
                    title: '发货方量',
                }, {
                    field: 'unload_volume',
                    title: '卸货方量',
                }, {
                    field: 'sign_volume',
                    title: '签收方量',
                }, {
                    field: 'ljcs',
                    title: '累计车次',
                }, {
                    field: 'ljfs_wc',
                    title: '累计方量',
                }, {
                    field: 'pcsj',
                    title: '派车时间',
                    width: 160,
                }, {
                    field: 'pump_plate_number',
                    title: '泵车车牌号',
                    width: 120,
                }],
            },
            task_number: '',
            tableList: [],
        };
    },

    created() {
        this.task_number = this.extr.task_number;
        this.selectListItem = this.tableData.headerData.map(item => item.field);
        this.getList();
    },
    methods: {
        // 获取场站列表
        getList() {
            this.$axios
                .get('/interfaceApi/production/productorder/product_task_detail/' + this.task_number)
                .then(data => {
                    if (data.length > 0) {
                        this.tableList = data;
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
.InvoiceList
    width 100%;
    height 100%;
    .viewbtn
        border 0;
        background: none;
        color: blue;
        text-decoration: underline;
        cursor: pointer;
    /deep/ .viewbtn
        border 0;
        background: none;
        color: blue;
        text-decoration: underline;
        cursor: pointer;
    .table-header
        height 0.51rem
        .btns
            float left
            .el-button
                color #fff
                background #2978ff
                padding 0 0.2rem
                border none
                line-height 0.36rem
        .conditionQuery
            width 3.6rem
            .el-input-group__append
                background #1577d2
                border 1px solid #1577d2
                .el-button
                    color #fff
        .setUp
            .el-button
                width 0.36rem
                height 0.36rem
                padding 0
                color #fff
                font-size 0.16rem
                background #1577d2
                margin-left 0.1rem
    .el-table
        height calc(100% - 0.7rem)
        th,td
            padding 0
        .editList
            >div
                vertical-align top
            .el-date-editor
                width 100% !important
</style>
